import React, { useCallback, useEffect, useState } from 'react'

import { withModal } from '../../common/Modal/Modal'
import { withPlugin } from '@flamingo_tech/funkgo'
import cx from '../../../utils/className'
import { rem as px2rem } from '../../../utils/remUtils'
import { getNextSevenDays } from '../../../utils/dateUtils'
import withCart from '../../../hooks/withCartAndPlugins'
import NewUserCouponCard from '../Coupon/NewUserCouponCard'
import Image from '../../../components/common/Image/Image'

import styles from './DrawModal.module.css'

const EVENT_CATEGORY = 'DrawFlow'

const SCENE = {
  AFTER_ORDER: 'afterOrder'
}

const RESULT_TYPE = {
  COUPON: 'coupon',
  FREE_GIFT: 'freeGift',
  COUPONS: 'coupons',
  WEB_TO_APP_FREE_GIFT: 'webToAppFreeGift'
}

const WebToAppFreeGiftSection = props => {

  const handleCloseWebToAppFreeGiftModal = () => {
    props.onCloseModalOnly()
  }

  const handleClickOK = () => {
    props.onClose()
  }

  return (
    <section className={styles.resultWrapper}>
      <div className={styles.closeIcon} onClick={handleCloseWebToAppFreeGiftModal} />
      <div className={cx(styles.resultContent, styles.webToAppGiftContent)}>
        <div className={styles.title}></div>
          <div className={styles.giftInfo}>
            <div className={styles.container}>
              <div className={styles.bg}></div>
              <div className={styles.giftWrapper}>
                <Image className={styles.gift} src={props.productImage} original />
              </div>
            </div>
            <div className={styles.desc}>{props.description}</div>
            <div className={styles.price}>
              <span className={styles.currentPrice}>{props.currentPrice}</span>
              <span className={styles.originalPrice}>{props.originalPrice}</span>
            </div>
          </div>
          <div className={cx(styles.btn, styles.fadeOutAnimation)} onClick={handleClickOK}>CLAIM IT IN APP</div>
        </div>
      <div className={cx(styles.bond, styles.delayBond)} />
    </section>
  )
}

const DrawResultModal = withModal(props => {

  const handleClickOK = () => {
    props.onClose()
  }

  return (
    <>
      {
        props.type !== RESULT_TYPE.WEB_TO_APP_FREE_GIFT
        ?
        <section className={cx(styles.resultWrapper, props.locale === 'de_DE' && styles.de)}>
          <div className={cx(styles.resultContent, props.type === RESULT_TYPE.FREE_GIFT && styles.giftContent, props.type === RESULT_TYPE.COUPONS && styles.couponsContent)}>
            {
              props.type === RESULT_TYPE.COUPON &&
              <div className={styles.content}>
                <div className={styles.title}>{props.couponTitle}</div>
                <div className={styles.rule}>{props.$i18n.transl('core.promotion.drawFlow.applyToAll')}</div>
                <div className={styles.time}>{getNextSevenDays(Date.now())}</div>
              </div>
            }
            {
              props.type === RESULT_TYPE.COUPONS &&
              <div className={styles.content}>
                <div className={styles.imageTitle}></div>
                <div className={styles.couponsWrapper}>
                {
                  props.coupons.map(coupon =>
                    <NewUserCouponCard key={coupon.id} {...coupon} />
                  )
                }
                </div>
                <div className={styles.couponsOkBtn} onClick={handleClickOK}>{props.$i18n.transl('common.ok')}</div>
              </div>
            }
            {
              props.type !== RESULT_TYPE.COUPONS
              &&
              <div className={styles.btn} onClick={handleClickOK}>{props.$i18n.transl('common.ok')}</div>
            }
          </div>
          <div className={styles.bond} />
        </section>
        :
        <WebToAppFreeGiftSection
          {...props}
        />
      }
    </>
  )
}, { maskClassName: styles.mask, disableCoverClick: true })

const rotateRectStyle = {
  0: {
    left: 7,
    top: 7
  },
  1: {
    left: 148,
    top: 7
  },
  2: {
    left: 148,
    top: 148,
  },
  3: {
    left: 7,
    top: 148,
  }
}


const rotateBeforeGameStart = (rotateRectRef) => {
  const refDom = rotateRectRef.current

  refDom.style.opacity = 1

  const numPrizes = 4
  let currentPosition = 0

  const rotateInterval = setInterval(() => {
    // 更新当前位置
    currentPosition = (currentPosition + 1) % numPrizes

    const { left, top } = rotateRectStyle[currentPosition]

    refDom.style.left = px2rem(left)
    refDom.style.top = px2rem(top)
  }, 800)

  return rotateInterval
}


const startGame = ({ finalPrize, rotateRectRef }) => {
  const refDom = rotateRectRef.current

  return new Promise((resolve) => {
    // 定义奖项的数量
    const numPrizes = 4

    // 定义旋转的圈数
    const numRotations = 5  // 这里假设要旋转5圈

    // 定义最终停在的奖项（0表示第一个奖项，1表示第二个奖项，以此类推）
    // const finalPrize = 2  // 假设最终停在第3个奖项

    // 计算旋转的总步数
    const totalSteps = numPrizes * numRotations + finalPrize

    let currentPosition = 0
    let step = 0
    let speed = 1

    function rotate() {
      // 更新当前位置
      currentPosition = (currentPosition + 1) % numPrizes

      const { left, top } = rotateRectStyle[currentPosition]

      refDom.style.left = px2rem(left)
      refDom.style.top = px2rem(top)

      step++

      // 控制速度
      if (step < totalSteps) {
        if (step >= totalSteps - numPrizes * 2) {
          speed -= 0.05 // 逐渐减速
        }
        setTimeout(rotate, 100 / speed) // 控制旋转速度，速度逐渐减小
      } else {
        // 忽闪忽闪两次
        let times = 8
        const shineInterval = setInterval(() => {
          if (times <= 0) {
            clearInterval(shineInterval)
            resolve()
          }
          times--
          refDom.style.opacity = times % 2

        }, 200)
      }
    }

    // 启动旋转
    rotate()
  })
}

let rotateInterval = null

let autoStartTriggered = false
let isInTheGame = false

const DrawModal = withPlugin(props => {
  const eventCategory = props.eventCategory || EVENT_CATEGORY

  const rotateRectRef = React.createRef()

  const [showResultModal, setShowResultModal] = useState(false)

  const [chances, setChances] = useState(props.chances)

  const { drawFlowOffer, maxChancesCount = 3, isAutoStart, hideCloseBtn = false, scene } = props

  const drawResult = drawFlowOffer[maxChancesCount - chances]

  const isApp = props.$detector.isApp()
  const isDesktop = props.$detector.isDesktop()
  const { locale } = props.$site.getSiteInfo()
  const { Trans } = props.$i18n

  const handleClickTryButton = useCallback(() => {
    if (!isInTheGame) {
      props.$track.event(eventCategory, 'click_try', maxChancesCount - chances)

      isInTheGame = true
      clearInterval(rotateInterval)
      rotateInterval = null

      startGame({
        finalPrize: (drawResult.type === RESULT_TYPE.COUPON || drawResult.type === RESULT_TYPE.COUPONS) ? 3 : 0,
        rotateRectRef
      }).then(() => {
        isInTheGame = false
        setShowResultModal(true)
      })
    }
  }, [chances, drawResult, eventCategory, maxChancesCount, props.$track, rotateRectRef])

  useEffect(() => {
    if (!rotateInterval && !(isAutoStart && !autoStartTriggered) && !isInTheGame) {
      rotateInterval = rotateBeforeGameStart(rotateRectRef)
    }
    if (isAutoStart && !autoStartTriggered) {
      autoStartTriggered = true
      handleClickTryButton()
    }
  }, [rotateRectRef, isAutoStart, handleClickTryButton])

  const handleClose = ({ isFinished }) => {
    isInTheGame = false
    clearInterval(rotateInterval)
    rotateInterval = null

    props.$track.event(eventCategory, 'close', maxChancesCount - chances)
    props.onClose({ isFinished })
  }



  const handleResultModalClose = () => {
    if (drawResult.type === RESULT_TYPE.COUPON) {
      props.$toastSuccess(props.$i18n.transl('core.promotion.drawFlow.takenCouponSuccess'))
      props.$store.couponHub.take(drawResult)
      props.$track.event(eventCategory, 'take_coupon', maxChancesCount - chances)
    } else if (drawResult.type === RESULT_TYPE.FREE_GIFT) {
      props.$toastSuccess(props.$i18n.transl('core.promotion.drawFlow.claimFreeGiftSuccessfully'))
      props.$addVariant({
        variant: { id: drawResult.skuId },
        quantity: 1
      })
      props.$track.event(eventCategory, 'take_free_gift', maxChancesCount - chances)
    } else if (drawResult.type === RESULT_TYPE.COUPONS) {
      props.onClaimCoupons()
      props.$track.event(eventCategory, 'take_coupons', maxChancesCount - chances)
    } else if (drawResult.type === RESULT_TYPE.WEB_TO_APP_FREE_GIFT) {
      props.$track.event(eventCategory, 'click_web_to_app_btn', maxChancesCount - chances)
      if (!isDesktop) {
        document.location.href = drawResult.link
        props.$track.event('AppDownload', 'click', 'after_order_free_gift_flow')
      } else {
        if (props.showAppChannelCard) {
          const qrCode = 'https://img.flamingo.shop/p/show/8ecd15d100a34fcda92d180fc497caee.png'
          props.showAppChannelCard(qrCode)
        }
      }
    }

    setTimeout(() => {
      setShowResultModal(false)

      const leftChances = chances - 1

      props.onTry(maxChancesCount - leftChances)

      setChances(leftChances)

      if (leftChances === 0) {
        handleClose({
          isFinished: true
        })
      }
    }, 300)
  }

  const handleCloseModal = () => {
    setTimeout(() => {
      setShowResultModal(false)

      const leftChances = chances - 1

      props.onTry(maxChancesCount - leftChances)

      setChances(leftChances)

      if (leftChances === 0) {
        handleClose({
          isFinished: true
        })
      }
    }, 1000)
  }

  const buttonText = scene === SCENE.AFTER_ORDER ? props.$i18n.transl('core.promotion.drawFlow.winOrderReward') : props.$i18n.transl('core.promotion.drawFlow.tryYourLuck')

  return (
    <>
      <section className={cx(styles.wrapper, isApp && styles.scaleAnimation, locale === 'de_DE' && styles.de)}>
        {!hideCloseBtn && <div className={styles.closeIcon} onClick={handleClose} />}
        <div className={styles.drawContent}>
          <div className={styles.content} />
          <div className={styles.shinning} ref={rotateRectRef} />
        </div>
        <div className={styles.chances}>
          {
            chances > 1 ?
            <Trans i18nKey='core.promotion.drawFlow.haveChances'>
              You still have <span>{{chances}}</span> chances
            </Trans> :
             <Trans i18nKey='core.promotion.drawFlow.haveOneChance'>
             You still have <span>1</span> chance
           </Trans>
          }
        </div>
        <div className={styles.btn} onClick={handleClickTryButton}>{buttonText}</div>
      </section>
      {
        showResultModal && <DrawResultModal locale={locale} $i18n={props.$i18n} {...drawResult} onClose={handleResultModalClose} onCloseModalOnly={handleCloseModal}/>
      }
    </>
  )
})

export default withModal(withCart(DrawModal), { maskClassName: styles.mask, disableCoverClick: true })