import React, { useState, useRef } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import EventsModal from '../../common/EventsModal/EventsModal'
import { STATIC_PAGE_HANDLES } from '../../../containers/Common/StaticModalContainer'

import CouponCardBox from './CouponCardBox'
import { getNextNinetyDays } from '../../../utils/dateUtils'

import { withModal } from '../../common/Modal/Modal'

import Button from '../../common/Button/Button'

import styles from './SupplementCard.module.css'
import Image from '../../common/Image/Image'
import cx from '../../../utils/className'

const COUPON_CARD_THEME_COLOR = '#FFEACD'

// 10% 优惠券卡片
const SupplementCard = withModal(props => {
  const [submitting, setSubmitting] = useState(false)
  const containerRef = useRef(null)
  const { locale } = props.$site.getSiteInfo()
  const { Trans } = props.$i18n
  const inputRef = useRef()
  const [email, setEmail] = useState()

  const enableEmailCollect = props.enableEmailCollect && !props.$user.getAnonymousLoginEmail()

  const handleSubmit = ev => {
    ev.preventDefault()

    if (enableEmailCollect) {
      if (!email || email.lastIndexOf('@') <= -1) {
        props.$toast(props.$i18n.transl('core.promotion.checkEmail'))
        inputRef.current.focus()
        return
      } else {
        props.$track.trackEmail(email, 'atcPopup')
        props.$user.saveAnonymousLoginEmail(email)
      }
    }

    if (!submitting) {
      props.$toast(props.$i18n.transl('core.promotion.wheel.luckyDay'))        // close entry
      const collectionCouponStorage = props.$storage.create('collection_coupons', { strategy: 'SESSION' })
      props.setRuntimeData('collection_coupons', {})
      collectionCouponStorage.setItem({})
      setTimeout(() => {
        props.onConfirm()
      }, 500)
      setSubmitting(true)
    }
  }


  const renderCouponInfo = coupon => {
    return (
      <div className={styles.couponInfo}>
        <div className={styles.couponTitle}>{coupon.title}</div>
        <div className={styles.couponSubTitle}>{coupon.subtitle}</div>
      </div>
    )
  }

  const renderCouponDesc = coupon => {
    const { effectiveDay, clientStartDate, clientEndDate } = coupon
    const cartCouponsDate = getNextNinetyDays(Date.now())
    const now = new Date()

    const couponDate =  (
      (clientStartDate || clientEndDate)
      ? `${new Date(clientStartDate || now).toLocaleDateString()} ~ ${new Date(clientEndDate || now).toLocaleDateString()}`
      : (
        effectiveDay > 0
        ? (effectiveDay > 1 ? props.$i18n.transl('core.promotion.validDays', { days: effectiveDay }) :  props.$i18n.transl('core.promotion.validDay', { days: effectiveDay }))
        : cartCouponsDate
      )
    )

    return (
      <div className={styles.couponDesc}>
        <div className={cx(styles.couponDate, effectiveDay > 0 && !clientEndDate && styles.highlighted)}>- {couponDate}</div>
        {
          coupon.maxDiscountAmount && <div className={styles.couponMaxOff}>- {coupon.maxDiscountAmount}</div>
        }
        <div className={styles.couponRule}>- {coupon.rule}</div>
      </div>
    )
  }

  const isUS = locale === 'en_US'

  return (
    <div className={cx(styles.wrapper, isUS && styles.us)}>
      <div className={styles.bgTitle}>SPECIAL OFFER</div>
      <section className={styles.card} id="supplement-card-element">
        <form className={styles.controller} onSubmit={handleSubmit}>
          <div className={styles.header}>{props.$i18n.transl('core.promotion.wheel.claimYourCoupons')}</div>

          <div className={cx(styles.container, enableEmailCollect && styles.withEmailInput)} ref={containerRef}>
            <div className={styles.images}>
              {
                props.couponList.map(coupon => {
                  // 兼容老版本
                  if (!coupon.title) {
                    return <Image
                      className={styles.couponImage}
                      objectFit='cover'
                      key={coupon.id}
                      src={coupon.couponImage}
                      placeholder={{ width: coupon.couponImageWidth, height: coupon.couponImageHeight }}
                    />
                  }

                  return (
                    <CouponCardBox
                      key={coupon.id}
                      renderTopContent={() => renderCouponInfo(coupon)}
                      renderBottomContent={() => renderCouponDesc(coupon)}
                      className={styles.couponBox}
                      backgroundColor={COUPON_CARD_THEME_COLOR}
                      circleBackgroundColor={isUS ? '#891111' : '#ee443d'}
                    />
                  )
                })
              }
            </div>
          </div>
          {
            enableEmailCollect &&
            <>
              <input
                ref={inputRef}
                type="email"
                className={styles.mainInput}
                placeholder={props.$i18n.transl('core.promotion.inputEmail')}
                onChange={e => setEmail(e.target.value)}
              />
              <div className={styles.privacyWrapper}>
                <Trans i18nKey='terms.agreeWithPrivacy'>
                  By registering, you agree to our<EventsModal className={styles.underline} handle={STATIC_PAGE_HANDLES.PRIVACY_POLICY}> Privacy Policy </EventsModal>
                </Trans>
              </div>
            </>
          }
          <Button
            className={styles.submitBtn}
            disabled={submitting}
            onClick={handleSubmit}>
            {props.$i18n.transl('core.promotion.wheel.claimIt')}
          </Button>
        </form>
      </section>
    </div>
  )
}, { showCloseButton: true, closeBtnClassName: styles.closeBtn, disableCoverClick: true })

export default withPlugin(SupplementCard)